import { DisplayValue, Untrusted, ValueWithUnit } from '@/types';
import { differenceInDays, parseISO } from 'date-fns';
import { kilometersToMile } from './conversion';
import parseDealer from './parseDealer';
import { parseRange } from './parseRange';
import {
  RawBatteryHealth,
  RawListingType,
  RawVehicleType,
  SpecificationType,
  VehicleType,
} from './schema/inventory/types';
import { filterUntrustedList } from './utils';

export const parseRawListingPrice = (
  price: Untrusted<RawListingType['Price']>
): DisplayValue => {
  if (typeof price === 'object' && price?.value) {
    return {
      displayValue: `$${price.value.toLocaleString()}`,
      value: Math.round(price.value),
    };
  }

  if (typeof price === 'number') {
    return {
      displayValue: `$${price.toLocaleString()}`,
      value: Math.round(price),
    };
  }

  return { displayValue: `Coming soon`, value: null };
};

export const parseRawMileage = (
  listing: Untrusted<RawListingType>
): ValueWithUnit => {
  if (listing.Mileage?.value && listing.Mileage?.unit) {
    const { value, unit } = listing.Mileage;
    if (typeof value === 'number') {
      if (typeof unit === 'string') {
        return {
          value: (unit === 'km'
            ? kilometersToMile(String(value))
            : Math.ceil(value)
          ).toLocaleString(),
          unit: 'mi',
        };
      }
    }
  }

  return {
    value: 0,
    unit: 'mi',
  };
};

const parseVehicle = (
  rawVehicle: RawVehicleType,
  rawBattery: RawBatteryHealth | null = null,
  withDetails = true
): VehicleType => {
  const listing = rawVehicle?.listing;

  return {
    vin: rawVehicle.Vin,
    year: rawVehicle?.Year ?? -1,
    make: rawVehicle?.Make ?? '',
    model: rawVehicle?.Model ?? '',
    trim: rawVehicle?.Trim ?? '',
    body: rawVehicle?.Body ?? '',
    fuelType: rawVehicle.FuelType || '',
    dealershipType: rawVehicle.DealershipType || '',
    horsepower: rawVehicle.Performance || null,
    condition:
      rawVehicle.Condition === 'Used'
        ? 'Pre-owned'
        : rawVehicle?.Condition ?? '',
    options: withDetails ? filterUntrustedList(rawVehicle?.Options) : [],
    features: withDetails
      ? filterUntrustedList(rawVehicle.Features?.map((f) => f && f.name))
      : [],
    listingId: listing?.ListingID ?? '',
    stockNumber: listing?.StockNumber || '',
    dealer: parseDealer(rawVehicle),
    price:
      listing?.Price !== undefined
        ? parseRawListingPrice(listing.Price)
        : { displayValue: 'Coming soon', value: null },
    mileage: listing ? parseRawMileage(listing) : { value: 0, unit: 'mi' },
    scraped: !!listing?.Scraped,
    description: (listing?.Desc || '').replace(/<br>/g, '\n'),
    images: filterUntrustedList(
      rawVehicle.s3_photos && rawVehicle.s3_photos.length > 0
        ? rawVehicle.s3_photos
        : listing?.Photos
    ),
    url: listing?.URL ?? '',
    location: listing?.Location ?? '',
    imageTimestamps: filterUntrustedList(listing?.PhotoTimeStamps || [])
      .map((n) => {
        const asUnixTimeStamp = new Date(n).getTime();
        return new Date(asUnixTimeStamp).toLocaleDateString('en-us', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        });
      })
      .filter((date) => date !== 'Invalid Date'),
    distance:
      listing && listing?.distance && listing.distance > 0
        ? listing.distance.toFixed(0)
        : '',
    rangeInfo: parseRange(rawVehicle),
    styleId: rawVehicle?.StyleId,
    overviews: [
      rawVehicle.Year
        ? {
            title: 'Year',
            text: rawVehicle.Year.toString(),
          }
        : null,
      rawVehicle.Make
        ? {
            title: 'Make',
            text: rawVehicle.Make,
          }
        : null,
      rawVehicle.Model
        ? {
            title: 'Model',
            text: rawVehicle.Model,
          }
        : null,
      rawVehicle.Trim
        ? {
            title: 'Trim',
            text: rawVehicle.Trim,
          }
        : null,
      rawVehicle.Body
        ? {
            title: 'Body',
            text: rawVehicle.Body,
          }
        : null,
      rawVehicle.NoOfPassengers
        ? {
            title: 'No. of Passengers',
            text: rawVehicle.NoOfPassengers,
          }
        : null,
      rawVehicle.Doors
        ? {
            title: 'Doors',
            text: rawVehicle.Doors,
          }
        : null,
      rawVehicle.ExteriorColor
        ? {
            title: 'Exterior Colour',
            text: rawVehicle.ExteriorColor,
          }
        : null,
      rawVehicle.InteriorColor
        ? {
            title: 'Interior Colour',
            text: rawVehicle.InteriorColor,
          }
        : null,
      listing?.Vin
        ? {
            title: 'VIN',
            text: listing.Vin,
          }
        : null,
      listing?.StockNumber
        ? {
            title: 'Stock Number',
            text: listing.StockNumber,
          }
        : null,
    ].filter((n): n is SpecificationType => n !== null),
    performances: [
      rawVehicle.Drive
        ? {
            title: 'Drive',
            text: rawVehicle.Drive,
          }
        : null,
      rawVehicle.EngineSize
        ? {
            title: 'Engine Size',
            text: rawVehicle.EngineSize,
          }
        : null,
      rawVehicle.Cylinders
        ? {
            title: 'Cylinders',
            text: rawVehicle.Cylinders,
          }
        : null,
      rawVehicle.Transmission
        ? {
            title: 'Transmission',
            text: rawVehicle.Transmission,
          }
        : null,
      rawVehicle.FuelType
        ? {
            title: 'Fuel Type',
            text: rawVehicle.FuelType,
          }
        : null,
    ].filter((n): n is SpecificationType => n !== null),
    ...(rawVehicle.Charging
      ? {
          chargingInfo: {
            isEV: rawVehicle?.FuelType?.toLowerCase() === 'electric',
            level1: rawVehicle.Charging.ChargingTime120V || '',
            level2: rawVehicle.Charging.ChargingTime240V || '',
            level3: rawVehicle.Charging.ChargingTimeDC || '',
            peakChargeRate: rawVehicle.Charging.ChargingPeakRateDC || 'N/A',
            chargingConnectorDC: rawVehicle.Charging.ChargingConnectorDC || '',
          },
        }
      : {
          chargingInfo: {
            level1: '',
            level2: '',
            level3: '',
            peakChargeRate: 'N/A',
            chargingConnectorDC: '',
          },
        }),
    batteryInfo: {
      batterySize: rawVehicle?.Battery?.size || 'Coming soon',
      batteryTech: rawVehicle?.Battery?.tech || 'Coming soon',
      batteryWarranty: rawVehicle.Battery?.warranty || null,
      batteryHealthEstimate: rawBattery?.BatteryHealthEstimate || null,
    },
    batteryWarranty: rawVehicle?.BatteryWarranty || '',
    listingWarranty: {
      description: listing?.WarrantyDescription || '',
      value: listing?.Warranty ? String(listing.Warranty) : '',
    },
    interiorColor: rawVehicle.InteriorColor || '',
    interiorColorCode: rawVehicle.IntColorCode || '',
    interiorColorHex: rawVehicle.IntColorHexCode || '',
    exteriorColor: rawVehicle.ExteriorColor || '',
    exteriorColorCode: rawVehicle.ExtColorCode || '',
    exteriorColorHex: rawVehicle.ExtColorHexCode || '',
    seats: rawVehicle.Seats || '',
    drivetrain: rawVehicle.Drive || '',
    badges: filterUntrustedList(rawVehicle.Badges),
    shortListingId: (rawVehicle?.ShortListingID as string) ?? null,
    incentives: rawVehicle?.incentives ?? null,
    createdAt: rawVehicle.created_at,
    listedForDays: differenceInDays(
      parseISO(rawVehicle.last_updated_at),
      parseISO(rawVehicle.created_at)
    ),
  };
};

export default parseVehicle;
